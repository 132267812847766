<template>
  <div
    class="ui-menu"
    :class="{'--submenu': depth > 0}"
  >
    <ui-menu-item
      v-for="(item,i) in value"
      :key="i"
      :value="item"
      :depth="depth"
      @click-item="$emit('click-item', $event)"
    ></ui-menu-item>
  </div>
</template>

<script>
import UiMenuItem from "./UiMenuItem.vue";

export default {
  name: "ui-menu",
  components: { UiMenuItem },

  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },

    depth: {
      type: Number,
      required: false,
      default: 0
    }
  }
};
</script>

<style lang="scss">
.ui-menu.--submenu {
  border-left: 1px solid rgba(0,0,0, .04);
  margin-left: 24px;
  padding-left: 12px;

  margin-bottom: 24px;
}
</style>