<template>
  <div class="ui-menu-item">
    <ui-drawer
      v-if="value.children && value.children.length"
      :drawer-group="drawerGroupName"
      :open="value.isActive"
    >
      <template #trigger="{ isOpen }">
        <ui-item
          v-bind="value"
          class="menu-item ui-noselect"
          :class="{'--active': value.isActive}"
        >
          <template #right>
            <ui-icon :value="`mdi:chevron-${isOpen?'up':'down'}`"></ui-icon>
          </template>
        </ui-item>
      </template>
      <template #contents>
        <ui-menu
          :depth="depth+1"
          :value="value.children"
          @click-item="$emit('click-item', $event)"
        ></ui-menu>
      </template>
    </ui-drawer>

    <ui-item
      v-else
      class="menu-item"
      :class="{'--active': value.isActive}"
      v-bind="value"
      @click="$emit('click-item', value)"
    ></ui-item>
  </div>
</template>

<script>
const UiMenu = () => import('./UiMenu.vue');
import { UiIcon, UiItem, UiDrawer } from '@/modules/ui/components';

export default {
  name: 'ui-menu-item',
  components: {
    UiMenu,
    UiIcon,
    UiItem,
    UiDrawer
  },

  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({})
    },

    depth: {
      type: Number,
      required: false,
      default: 0
    }
  },

  computed: {
    drawerGroupName() {
      return `ui-menu-${this.depth}`;
    }
  }
};
</script>

<style lang="scss">
.ui-menu-item {
  .menu-item {
    cursor: pointer;
    display: flex;
    border-radius: var(--ui-radius);

    color: #444;
    font-size: 0.85em !important;
    font-weight: bold;

    &.--active {
      color: var(--ui-color-primary);

      .ui-icon {
        color: var(--ui-color-primary) !important;
      }
    }
  }
}
</style>